<script setup lang="ts">
import type { ProductCategory } from '~/types/api'

const props = withDefaults(
  defineProps<{
    small?: boolean
    categories: ProductCategory[]
  }>(),
  {
    small: false,
  }
)

const { getResourceUrl } = useResources()

const localePath = useLocalePath()

const { locale } = useI18n()

const { data: smartFiltersData, status } = await useLazyAsyncData(
  'smartFilters_' + locale.value,
  () => createProductSmartFiltersRepository().getAll()
)

const smartFilters = computed(() => smartFiltersData.value?.items || [])
</script>

<template>
  <div class="py-5 @container lg:container">
    <div class="grid grid-cols-1 gap-8 @md:grid-cols-2 @6xl:grid-cols-3">
      <div
        class="flex flex-col"
        :class="small ? 'flex-col' : 'flex-row justify-center'"
      >
        <p class="font-pragmatica-ext mb-4 text-xl font-bold">
          {{ $t('base.header.categories') }}
        </p>
        <div class="mb-4">
          <div v-for="category in categories" :key="category.nid">
            <div :class="props.small ? 'p-2' : 'py-2'">
              <NuxtLink
                :to="
                  localePath({
                    name: 'product-lines-category-slug',
                    params: { slug: category.urlSlug },
                  })
                "
                class="group/category font-bold no-underline transition duration-300"
              >
                <div class="flex flex-row text-lg">
                  <img
                    :height="35"
                    :width="35"
                    loading="lazy"
                    :src="getResourceUrl(category.image?.url)"
                  />
                  <div class="pt-5">
                    {{ category.title }}
                    <span
                      class="block h-[1px] max-w-0 bg-black transition-all duration-500 group-hover/category:max-w-full"
                    />
                  </div>
                </div>
              </NuxtLink>
            </div>
          </div>
        </div>
      </div>
      <div>
        <p class="font-pragmatica-ext mb-4 text-xl font-bold">
          {{ $t('base.header.featured') }}
        </p>
        <div class="flex flex-col">
          <NuxtLink
            :to="
              localePath({
                name: 'products-slug',
                params: { slug: smartFilter.urlSlug },
              })
            "
            v-for="smartFilter in smartFilters"
            class="group/category font-bold no-underline transition duration-300"
          >
            <div class="w-fit">
              {{ smartFilter.title }}
              <span
                class="block h-[1px] max-w-0 bg-black transition-all duration-500 group-hover/category:max-w-full"
              />
            </div>
          </NuxtLink>
        </div>
      </div>
      <div class="mb-5">
        <NuxtLink
          :to="
            localePath({
              name: 'product-lines',
            })
          "
          class="group/category font-bold no-underline transition duration-300"
        >
          <p class="font-pragmatica-ext mb-4 w-fit text-xl font-bold">
            {{ $t('base.header.productLines') }}
            <span
              class="block h-[1px] max-w-0 bg-black transition-all duration-500 group-hover/category:max-w-full"
            />
          </p>
          <div class="flex">
            <div class="w-1/2">
              <NuxtImg
                :src="
                  withHost(
                    '/file/nodes/product/images/harmony-modern-890-f37n6-v1-po.webp'
                  )
                "
                sizes="300px"
              ></NuxtImg>
            </div>
            <div class="w-1/2">
              <NuxtImg
                :src="
                  withHost(
                    '/file/nodes/productline/gallery/Harmony-Modern_870,F70-N6_po.webp'
                  )
                "
                sizes="300px"
                class="mt-8 w-40"
              ></NuxtImg>
            </div>
          </div>
        </NuxtLink>
      </div>
    </div>
    <hr v-if="!props.small" class="mb-5 h-px border-0 !bg-black" />
    <div class="flex justify-center">
      <NuxtLink
        :to="localePath({ name: 'products' })"
        class="group/products font-bold no-underline transition duration-300"
        :class="{ 'text-lg': props.small }"
      >
        <div :class="props.small ? 'p-1' : 'p-5'">
          {{ $t('base.products.productsLink') }}
          <span
            class="block h-[1px] max-w-0 bg-black transition-all duration-500 group-hover/products:max-w-full"
          />
        </div>
      </NuxtLink>
    </div>
  </div>
</template>

<style scoped></style>
